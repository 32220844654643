import { createBrowserRouter, createHashRouter } from "react-router-dom";

import Root from "./Root";
import Home from "./screens/Home";

import AdvisorCall from "./screens/AdvisorCall";
import CustomerCall from "./screens/CustomerCall";

import NotFound from "./screens/error/NotFound";
import ErrorComponent from "./screens/error/ErrorComponent";

const router = createHashRouter(
  [
    {
      path: "/",
      element: <Root />,
      children: [
        {
          path: "",
          element: <Home />,
          errorElement: <ErrorComponent />,
        },
        {
          path: "advisor/call",
          element: <AdvisorCall />,
          errorElement: <ErrorComponent />,
        },
        {
          path: "customer/call",
          element: <CustomerCall />,
          errorElement: <ErrorComponent />,
        },
      ],
      errorElement: <NotFound />,
    },
  ],
  {
    basename: "/",
  }
);

export default router;
