import { useState, useRef, useEffect } from "react";
import { List } from "antd";

import callService from "../services/callService";

const AudioRecorder = ({ title, callId, speakerDiv }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [segments, setSegments] = useState([]);
  const [sessionId, setSessionId] = useState(null);
  const [monitoringSessionId, setMonitoringSessionId] = useState(null);
  const wsRef = useRef(null);
  const monitorWsRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const buffersize = 8000;

  const textContainerRef = useRef(null);

  useEffect(() => {
    if (textContainerRef.current) {
      textContainerRef.current.scrollTop =
        textContainerRef.current.scrollHeight;
    }
  }, [segments]);

  const startRecording = async () => {
    try {
      setSegments([]);
      setSessionId(null);
      setMonitoringSessionId(null);
      // const callId = "b944894f-bed2-40b5-97d2-0b6b92eb5ee9";
      // const speakerDiv = "t1";

      const wsUrl = `wss://haiv.timbel.net:40002/client/ws/speech?single=false&model=KOREAN_ONLINE_16K&call-id=${callId}&speaker-div=${speakerDiv}&project=2e77c961-f709-400a-8c3e-0eeb73604698`;
      // const wsUrl = `wss://haiv.timbel.net:40002/client/ws/speech?single=false&model=KOREAN_ONLINE_8K&call-id=${callId}&speaker-div=${speakerDiv}&project=2ec95f1c-3b52-4eaa-a29a-6065e2d95d61`;
      const ws = new WebSocket(wsUrl);
      wsRef.current = ws;

      ws.onopen = async () => {
        console.log("WebSocket connected to STT server:", wsUrl);
        console.log("Call ID:", callId);
      };

      ws.onmessage = async (event) => {
        const message = event.data;
        console.log("STT message:", message);

        if (!sessionId) {
          try {
            const data = JSON.parse(message);
            if (data.sessionId) {
              console.log("Received sessionId:", data.sessionId);
              setSessionId(data.sessionId);

              const response = await callService.startAdvisorCall({
                callId,
                sessionId: data.sessionId,
                speakerDiv,
              });

              const { monitoringSessionId } = response;
              setMonitoringSessionId(monitoringSessionId);
              console.log("Monitoring session started:", monitoringSessionId);
            }
          } catch (error) {
            console.error("Failed to parse STT message:", error);
          }
        } else {
          console.log("Session ID already processed, skipping further checks.");
        }

        processSTTMessage(message);
      };

      ws.onclose = () => {
        console.log("WebSocket connection to STT server closed.");
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      const mediaStream = await navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: false,
        })
        .catch((err) => {
          console.error("Error accessing microphone:", err);
          alert("Please allow microphone access in your browser settings.");
          throw err;
        });

      const mediaRecorder = new MediaRecorder(mediaStream);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          processAudioChunk(event.data);
        }
      };

      mediaRecorder.start(1000);
      setIsRecording(true);
    } catch (err) {
      console.error("Error accessing microphone:", err);
      if (err.name === "NotAllowedError") {
        alert("Microphone access denied.");
      }
    }
  };

  const stopRecording = async () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    if (wsRef.current) {
      wsRef.current.close();
    }
    if (monitorWsRef.current) {
      monitorWsRef.current.close();
    }
    setIsRecording(false);
    console.log("Recording stopped.");

    if (monitoringSessionId) {
      try {
        const response = await callService.stopAdvisorCall({
          monitoringSessionId,
        });
        console.log("Stopped monitoring session:", response);
      } catch (err) {
        console.error("Error stopping monitoring session:", err);
      }
    } else {
      console.warn("No monitoringSessionId available to stop.");
    }
  };

  const processAudioChunk = (audioBlob) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(audioBlob);

    fileReader.onload = () => {
      const audioBuffer = fileReader.result;
      sendAudioToWebSocket(audioBuffer);
    };
  };

  const sendAudioToWebSocket = (audioBuffer) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      let pos = 0;
      while (pos < audioBuffer.byteLength) {
        const chunk = audioBuffer.slice(pos, pos + buffersize);
        wsRef.current.send(chunk);
        pos += buffersize;
      }
      console.log(`Sent audio chunk of size ${audioBuffer.byteLength} bytes.`);
    } else {
      console.error("WebSocket is not connected.");
    }
  };

  const processSTTMessage = (message) => {
    try {
      const data = JSON.parse(message);
      if (data?.result?.hypotheses?.length > 0) {
        const transcript = data.result.hypotheses[0].transcript;
        const isFinal = data.result.final;
        const startTime = data["segment-start"];

        setSegments((prevSegments) => {
          const newSegments = [...prevSegments];

          if (isFinal) {
            if (newSegments.length > 0) {
              newSegments[newSegments.length - 1] = `(${startTime.toFixed(
                2
              )}) ${transcript}`;
            } else {
              newSegments.push(`(${startTime.toFixed(2)}) ${transcript}`);
            }
            newSegments.push("");
          } else {
            console.log("transcript", transcript);
            if (newSegments.length > 0) {
              newSegments[newSegments.length - 1] = transcript;
            } else {
              newSegments.push(transcript);
            }
          }

          return newSegments;
        });
      }
    } catch (error) {
      console.error("Error processing STT message:", error);
    }
  };

  const connectToMonitoringWs = (sessionId) => {
    const monitorWsUrl = `wss://haiv.timbel.net:40002/client/ws/monitoring?original-session-id=${sessionId}`;
    const monitorWs = new WebSocket(monitorWsUrl);
    monitorWsRef.current = monitorWs;

    monitorWs.onopen = () => {
      console.log("Monitoring WebSocket connected:", monitorWsUrl);
    };

    monitorWs.onmessage = (event) => {
      const monitorMessage = event.data;
      console.log("Monitoring message:", monitorMessage);
    };

    monitorWs.onclose = () => {
      console.log("Monitoring WebSocket connection closed.");
    };

    monitorWs.onerror = (error) => {
      console.error("Monitoring WebSocket error:", error);
    };
  };

  return (
    <div className="flex flex-col items-center space-y-6">
      <button
        className={`mt-5 mb-5 px-4 py-2 rounded text-white font-semibold shadow ${
          isRecording
            ? "bg-red-500 hover:bg-red-600"
            : "bg-blue-500 hover:bg-blue-600"
        }`}
        onClick={isRecording ? stopRecording : startRecording}
      >
        {isRecording ? "전송 중지" : "전송 시작"}
      </button>

      <h3 className="text-xl font-semibold mr-[380px]">{title}</h3>

      <div
        className="langsa-scrollType-1 w-[450px] h-[70vh] overflow-y-auto"
        ref={textContainerRef}
      >
        <List
          className="rounded-none text-base"
          bordered
          dataSource={segments}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      </div>
    </div>
  );
};

export default AudioRecorder;
