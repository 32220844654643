import { useEffect, useState, useRef } from "react";
import { List } from "antd";

import { ReactComponent as RedCircleIcon } from "../assets/images/redCircle.svg";
import { ReactComponent as GreenCircleIcon } from "../assets/images/greenCircle.svg";

import mockupSvc from "../services/mockup";
import stompClient from "../services/stompClient";

const scrollToBottom = (ref) =>
  (ref.current.scrollTop = ref.current.scrollHeight);

export default function Dialogue() {
  const [messages, setMessages] = useState([]);
  const [connected, setConnected] = useState(false);
  const [callStatus, setCallStatus] = useState(false);
  const [resetFlag, setResetFlag] = useState(false);
  const [sessionIdTx, setSessionIdTx] = useState(null);
  const [sessionIdRx, setSessionIdRx] = useState(null);
  const segmentRef = useRef();

  useEffect(() => {
    const connectAndSubscribe = async () => {
      try {
        if (!stompClient.isConnected()) {
          console.log("Connecting STOMP client...");
          await stompClient.connect();
        }

        console.log("Subscribing to channels...");
        stompClient.subscribe("/channel/call", (message) => {
          console.log("Received message on /channel/call:", message.body);
          setMessages((prevMessages) => [...prevMessages, message.body]);
        });

        stompClient.subscribe("/channel/status", (message) => {
          console.log("Received message on /channel/status:", message.body);
          setCallStatus(message.body === "ON");
        });
      } catch (err) {
        console.error(
          "Error during STOMP client connection or subscription:",
          err
        );
      }
    };

    connectAndSubscribe();

    return () => {
      stompClient.disconnect();
      console.log("STOMP client disconnected.");
    };
  }, []);

  // useEffect(() => {
  //   const onConnect = async () => {
  //     console.log("Connected");
  //     setConnected(true);

  //     // 채널 구독
  //     await stompClient.connect();
  //     stompClient.subscribe("/channel/call", (message) => {
  //       console.log("wefwefwef", message);
  //       setCallStatus(true);
  //       setMessages((prevMessages) => [...prevMessages, message.body]);
  //     });

  //     stompClient.subscribe("/channel/status", (message) => {
  //       console.log("wefwefwef11111", message);
  //       setCallStatus(message.body === "ON");
  //     });
  //   };

  //   const onDisconnect = () => {
  //     console.log("Disconnected");
  //     setConnected(false);
  //   };

  //   stompClient.connect(onConnect);

  //   return () => {
  //     stompClient.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    scrollToBottom(segmentRef);
  }, [messages]);

  useEffect(() => {
    if (resetFlag) {
      setMessages([]);
      setResetFlag(false);
    }
  }, [resetFlag]);

  const startCall = async () => {
    try {
      setResetFlag(true);
      const response = await mockupSvc.start();
      const { sessionIdTx, sessionIdRx } = response;

      setSessionIdTx(sessionIdTx);
      setSessionIdRx(sessionIdRx);
      setCallStatus(true);

      console.log("Call started:", { sessionIdTx, sessionIdRx });
    } catch (err) {
      console.error("Error starting call:", err);
      alert("서버 응답이 없습니다.");
    }
  };

  const stopCall = async () => {
    try {
      if (!sessionIdTx || !sessionIdRx) {
        alert("No active sessions to stop.");
        return;
      }

      await mockupSvc.stop({ sessionIdTx, sessionIdRx });
      setSessionIdTx(null);
      setSessionIdRx(null);
      setCallStatus(false);

      console.log("Call stopped.");
    } catch (err) {
      console.error("Error stopping call:", err);
      alert("서버 응답이 없습니다.");
    }
  };

  return (
    <>
      <div className="flex p-2 mb-3">
        <button
          type="button"
          className="rounded-md bg-slate-600 px-3.5 py-2.5 mr-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
          onClick={startCall}
        >
          전송 시작
        </button>
        <button
          type="button"
          className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={stopCall}
        >
          전송 중지
        </button>

        {callStatus ? (
          <GreenCircleIcon className="ml-3 mt-0.5" />
        ) : (
          <RedCircleIcon className="ml-3 mt-0.5" />
        )}
      </div>

      <div
        className="langsa-scrollType-1 w-[450px] h-[70vh] overflow-y-auto"
        ref={segmentRef}
      >
        <List
          className="rounded-none"
          bordered
          dataSource={messages}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      </div>
    </>
  );
}
