import axios from "axios";

const startAdvisorCall = async ({ callId, speakerDiv, sessionId }) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_GATEWAY_ADV_PROTOCOL}://${process.env.REACT_APP_GATEWAY_ADV_HOST}/call/advisor/start`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { callId, speakerDiv, sessionId },
    };

    const response = await axios(config);
    const data = response.data;
    console.log("Server response =>", {
      status: response.status,
      statusText: response.statusText,
      data,
    });

    return data;
  } catch (err) {
    throw err;
  }
};

const stopAdvisorCall = async ({ monitoringSessionId }) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_GATEWAY_ADV_PROTOCOL}://${process.env.REACT_APP_GATEWAY_ADV_HOST}/call/advisor/stop`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { sessionId: monitoringSessionId },
    };

    const response = await axios(config);
    const data = response.data;
    console.log("Server response =>", {
      status: response.status,
      statusText: response.statusText,
      data,
    });

    return data;
  } catch (err) {
    throw err;
  }
};

export default { startAdvisorCall, stopAdvisorCall };
