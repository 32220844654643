import axios from "axios";

const makeRequest = async (config) => {
  try {
    const response = await axios(config);
    const data = response.data;
    // console.log(`[ ${config.url} ] Server response =>`, {
    //   status: response.status,
    //   statusText: response.statusText,
    //   data: data,
    // });
    return data;
  } catch (err) {
    console.error(`[ ${config.url} ] Error =>`, err);
    throw err;
  }
};

const createConfig = (path, data = null) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_GATEWAY_ADV_PROTOCOL}://${process.env.REACT_APP_GATEWAY_ADV_HOST}/call/${path}`,
    headers: { "Content-Type": "application/json" },
    responseType: "json",
    withCredentials: true,
  };

  if (data) config.data = data;
  return config;
};

const start = async () => makeRequest(createConfig("start"));
const stop = async (data) => makeRequest(createConfig("stop", data));

export default { start, stop };
